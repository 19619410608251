import { default as aboutKJAmqyq7bDMeta } from "/usr/src/app/pages/about.vue?macro=true";
import { default as accountdWl6ticfZzMeta } from "/usr/src/app/pages/account.vue?macro=true";
import { default as analyticsl7Omsc4s16Meta } from "/usr/src/app/pages/analytics.vue?macro=true";
import { default as bio_45ageDzAmsXghmWMeta } from "/usr/src/app/pages/bio-age.vue?macro=true";
import { default as indexcozPwSvdWzMeta } from "/usr/src/app/pages/book/index.vue?macro=true";
import { default as thank_45youryj1t4Qt40Meta } from "/usr/src/app/pages/book/thank-you.vue?macro=true";
import { default as bookingsVYnCrDVpgLMeta } from "/usr/src/app/pages/bookings.vue?macro=true";
import { default as contactkLj0iaDgVSMeta } from "/usr/src/app/pages/contact.vue?macro=true";
import { default as dashboardhKii8fXWAxMeta } from "/usr/src/app/pages/dashboard.vue?macro=true";
import { default as homeaogcECheA9Meta } from "/usr/src/app/pages/home.vue?macro=true";
import { default as how_45it_45workslLJki3cGeAMeta } from "/usr/src/app/pages/how-it-works.vue?macro=true";
import { default as impersonateHjB1Cu0w9aMeta } from "/usr/src/app/pages/impersonate.vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as indexFQZGrnJt97Meta } from "/usr/src/app/pages/library/index.vue?macro=true";
import { default as pathwaysGSTdirk52SMeta } from "/usr/src/app/pages/library/pathways.vue?macro=true";
import { default as plansjfcwyepCTlMeta } from "/usr/src/app/pages/library/plans.vue?macro=true";
import { default as protocols4sIbUkB9T9Meta } from "/usr/src/app/pages/library/protocols.vue?macro=true";
import { default as librarycUPQVoYVIMMeta } from "/usr/src/app/pages/library.vue?macro=true";
import { default as login_45finalisedhE9DUIgXcMeta } from "/usr/src/app/pages/login-finalise.vue?macro=true";
import { default as loginRGcvZC480vMeta } from "/usr/src/app/pages/login.vue?macro=true";
import { default as messageseMkAPWuX7KMeta } from "/usr/src/app/pages/messages.vue?macro=true";
import { default as planPjwTaIzKuBMeta } from "/usr/src/app/pages/plan.vue?macro=true";
import { default as indexES59BF5KqjMeta } from "/usr/src/app/pages/portal-book/index.vue?macro=true";
import { default as thank_45you1Qft4WZ1UsMeta } from "/usr/src/app/pages/portal-book/thank-you.vue?macro=true";
import { default as privacyETeYDW5ioPMeta } from "/usr/src/app/pages/privacy.vue?macro=true";
import { default as _91service_93gRKfYNW5BBMeta } from "/usr/src/app/pages/provider/[provider]/[service].vue?macro=true";
import { default as indextaUVoQ1XxQMeta } from "/usr/src/app/pages/provider/[provider]/index.vue?macro=true";
import { default as healthcare_45websiteiZyuBr72IvMeta } from "/usr/src/app/pages/providers/healthcare-website.vue?macro=true";
import { default as indexqq09IeQcYDMeta } from "/usr/src/app/pages/providers/index.vue?macro=true";
import { default as redirectz5TQNPlL3hMeta } from "/usr/src/app/pages/redirect.vue?macro=true";
import { default as _91procedure_93sxyNDlDFejMeta } from "/usr/src/app/pages/search-results/[procedure].vue?macro=true";
import { default as _91procedure_93c9ph2EGAg3Meta } from "/usr/src/app/pages/select/[procedure].vue?macro=true";
import { default as _91_46_46_46locations_933otyI0s3fSMeta } from "/usr/src/app/pages/services/[procedure]/[...locations].vue?macro=true";
import { default as indexUObv4kbtLSMeta } from "/usr/src/app/pages/services/index.vue?macro=true";
import { default as signup7mnpplySxfMeta } from "/usr/src/app/pages/signup.vue?macro=true";
import { default as termsZtn5bNC2S8Meta } from "/usr/src/app/pages/terms.vue?macro=true";
import { default as testingr8usFONtNUMeta } from "/usr/src/app/pages/testing.vue?macro=true";
import { default as analyticsOFyYZXZsmpMeta } from "/usr/src/app/pages/users/[id]/analytics.vue?macro=true";
import { default as anamnesistl1CcZrZb1Meta } from "/usr/src/app/pages/users/[id]/anamnesis.vue?macro=true";
import { default as assessment2RjC4Q053nMeta } from "/usr/src/app/pages/users/[id]/assessment.vue?macro=true";
import { default as indexGUF9WqX8ygMeta } from "/usr/src/app/pages/users/[id]/index.vue?macro=true";
import { default as info60iH2NXXlcMeta } from "/usr/src/app/pages/users/[id]/info.vue?macro=true";
import { default as lab_45resultsUzhRKLYFmRMeta } from "/usr/src/app/pages/users/[id]/lab-results.vue?macro=true";
import { default as messageslbWG7YL68dMeta } from "/usr/src/app/pages/users/[id]/messages.vue?macro=true";
import { default as treatmentZMjItgAp2dMeta } from "/usr/src/app/pages/users/[id]/treatment.vue?macro=true";
import { default as indexBr8R1jq2QLMeta } from "/usr/src/app/pages/users/index.vue?macro=true";
import { default as users581yferDv0Meta } from "/usr/src/app/pages/users.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    meta: aboutKJAmqyq7bDMeta || {},
    component: () => import("/usr/src/app/pages/about.vue")
  },
  {
    name: "account",
    path: "/account",
    meta: accountdWl6ticfZzMeta || {},
    component: () => import("/usr/src/app/pages/account.vue")
  },
  {
    name: "analytics",
    path: "/analytics",
    meta: analyticsl7Omsc4s16Meta || {},
    component: () => import("/usr/src/app/pages/analytics.vue")
  },
  {
    name: "bio-age",
    path: "/bio-age",
    meta: bio_45ageDzAmsXghmWMeta || {},
    component: () => import("/usr/src/app/pages/bio-age.vue")
  },
  {
    name: "book",
    path: "/book",
    meta: indexcozPwSvdWzMeta || {},
    component: () => import("/usr/src/app/pages/book/index.vue")
  },
  {
    name: "book-thank-you",
    path: "/book/thank-you",
    meta: thank_45youryj1t4Qt40Meta || {},
    component: () => import("/usr/src/app/pages/book/thank-you.vue")
  },
  {
    name: "bookings",
    path: "/bookings",
    meta: bookingsVYnCrDVpgLMeta || {},
    component: () => import("/usr/src/app/pages/bookings.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactkLj0iaDgVSMeta || {},
    component: () => import("/usr/src/app/pages/contact.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/usr/src/app/pages/dashboard.vue")
  },
  {
    name: "home",
    path: "/home",
    meta: homeaogcECheA9Meta || {},
    component: () => import("/usr/src/app/pages/home.vue")
  },
  {
    name: "how-it-works",
    path: "/how-it-works",
    meta: how_45it_45workslLJki3cGeAMeta || {},
    component: () => import("/usr/src/app/pages/how-it-works.vue")
  },
  {
    name: "impersonate",
    path: "/impersonate",
    meta: impersonateHjB1Cu0w9aMeta || {},
    component: () => import("/usr/src/app/pages/impersonate.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexgw2ikJsyhcMeta || {},
    component: () => import("/usr/src/app/pages/index.vue")
  },
  {
    name: librarycUPQVoYVIMMeta?.name,
    path: "/library",
    meta: librarycUPQVoYVIMMeta || {},
    component: () => import("/usr/src/app/pages/library.vue"),
    children: [
  {
    name: "library",
    path: "",
    component: () => import("/usr/src/app/pages/library/index.vue")
  },
  {
    name: "library-pathways",
    path: "pathways",
    component: () => import("/usr/src/app/pages/library/pathways.vue")
  },
  {
    name: "library-plans",
    path: "plans",
    component: () => import("/usr/src/app/pages/library/plans.vue")
  },
  {
    name: "library-protocols",
    path: "protocols",
    component: () => import("/usr/src/app/pages/library/protocols.vue")
  }
]
  },
  {
    name: "login-finalise",
    path: "/login-finalise",
    meta: login_45finalisedhE9DUIgXcMeta || {},
    component: () => import("/usr/src/app/pages/login-finalise.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginRGcvZC480vMeta || {},
    component: () => import("/usr/src/app/pages/login.vue")
  },
  {
    name: "messages",
    path: "/messages",
    meta: messageseMkAPWuX7KMeta || {},
    component: () => import("/usr/src/app/pages/messages.vue")
  },
  {
    name: "plan",
    path: "/plan",
    meta: planPjwTaIzKuBMeta || {},
    component: () => import("/usr/src/app/pages/plan.vue")
  },
  {
    name: "portal-book",
    path: "/portal-book",
    meta: indexES59BF5KqjMeta || {},
    component: () => import("/usr/src/app/pages/portal-book/index.vue")
  },
  {
    name: "portal-book-thank-you",
    path: "/portal-book/thank-you",
    meta: thank_45you1Qft4WZ1UsMeta || {},
    component: () => import("/usr/src/app/pages/portal-book/thank-you.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyETeYDW5ioPMeta || {},
    component: () => import("/usr/src/app/pages/privacy.vue")
  },
  {
    name: "provider-provider-service",
    path: "/provider/:provider()/:service()",
    meta: _91service_93gRKfYNW5BBMeta || {},
    component: () => import("/usr/src/app/pages/provider/[provider]/[service].vue")
  },
  {
    name: "provider-provider",
    path: "/provider/:provider()",
    meta: indextaUVoQ1XxQMeta || {},
    component: () => import("/usr/src/app/pages/provider/[provider]/index.vue")
  },
  {
    name: "providers-healthcare-website",
    path: "/providers/healthcare-website",
    meta: healthcare_45websiteiZyuBr72IvMeta || {},
    component: () => import("/usr/src/app/pages/providers/healthcare-website.vue")
  },
  {
    name: "providers",
    path: "/providers",
    meta: indexqq09IeQcYDMeta || {},
    component: () => import("/usr/src/app/pages/providers/index.vue")
  },
  {
    name: "redirect",
    path: "/redirect",
    component: () => import("/usr/src/app/pages/redirect.vue")
  },
  {
    name: "search-results-procedure",
    path: "/search-results/:procedure()",
    meta: _91procedure_93sxyNDlDFejMeta || {},
    component: () => import("/usr/src/app/pages/search-results/[procedure].vue")
  },
  {
    name: "select-procedure",
    path: "/select/:procedure()",
    meta: _91procedure_93c9ph2EGAg3Meta || {},
    component: () => import("/usr/src/app/pages/select/[procedure].vue")
  },
  {
    name: "services-procedure-locations",
    path: "/services/:procedure()/:locations(.*)*",
    meta: _91_46_46_46locations_933otyI0s3fSMeta || {},
    component: () => import("/usr/src/app/pages/services/[procedure]/[...locations].vue")
  },
  {
    name: "services",
    path: "/services",
    meta: indexUObv4kbtLSMeta || {},
    component: () => import("/usr/src/app/pages/services/index.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: signup7mnpplySxfMeta || {},
    component: () => import("/usr/src/app/pages/signup.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsZtn5bNC2S8Meta || {},
    component: () => import("/usr/src/app/pages/terms.vue")
  },
  {
    name: "testing",
    path: "/testing",
    meta: testingr8usFONtNUMeta || {},
    component: () => import("/usr/src/app/pages/testing.vue")
  },
  {
    name: users581yferDv0Meta?.name,
    path: "/users",
    meta: users581yferDv0Meta || {},
    component: () => import("/usr/src/app/pages/users.vue"),
    children: [
  {
    name: "users-id-analytics",
    path: ":id()/analytics",
    component: () => import("/usr/src/app/pages/users/[id]/analytics.vue")
  },
  {
    name: "users-id-anamnesis",
    path: ":id()/anamnesis",
    component: () => import("/usr/src/app/pages/users/[id]/anamnesis.vue")
  },
  {
    name: "users-id-assessment",
    path: ":id()/assessment",
    component: () => import("/usr/src/app/pages/users/[id]/assessment.vue")
  },
  {
    name: "users-id",
    path: ":id()",
    component: () => import("/usr/src/app/pages/users/[id]/index.vue")
  },
  {
    name: "users-id-info",
    path: ":id()/info",
    component: () => import("/usr/src/app/pages/users/[id]/info.vue")
  },
  {
    name: "users-id-lab-results",
    path: ":id()/lab-results",
    component: () => import("/usr/src/app/pages/users/[id]/lab-results.vue")
  },
  {
    name: "users-id-messages",
    path: ":id()/messages",
    component: () => import("/usr/src/app/pages/users/[id]/messages.vue")
  },
  {
    name: "users-id-treatment",
    path: ":id()/treatment",
    component: () => import("/usr/src/app/pages/users/[id]/treatment.vue")
  },
  {
    name: "users",
    path: "",
    meta: indexBr8R1jq2QLMeta || {},
    component: () => import("/usr/src/app/pages/users/index.vue")
  }
]
  }
]